import React from 'react'
import { Link, withPrefix, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import ReadyToWork from '../components/readyToWork'
import AreYouAnAgency from '../components/areYouAnAgency'
import HomepageSVG from '../components/backgroundSVGS/HomepageSVG'
import CrewSVG from '../components/backgroundSVGS/CrewSVG'

import TipCard from '../components/projectCards/TipCard';
// import Love2BuildCard from '../components/projectCards/Love2BuildCard';
import MedicalCard from '../components/projectCards/MedicalCard';
import PeelCard from '../components/projectCards/PeelCard';
import PergolasCard from '../components/projectCards/PergolasCard';
import CirenHousingCard from '../components/projectCards/CirenHousingCard';

const IndexPage = props => (
  <Layout>
    <Helmet
      title="Element Studio - Cheltenham Web Design &amp; Development"
      meta={[
        {
          name: 'description',
          content:
            'Element Studio is a Web Design and Development agency based in Cheltenham, specialising in bespoke web applications built in Laravel PHP and React JS.',
        },
      ]}
    />
    <header className="hero hero--fullscreen hero--home">
        <Img
          fluid={props.data.homeHero.childImageSharp.fluid}
          alt="The Matterhorn in the distance, framed though a forested valley against a blue sky"
          className="hero__bg"
        />
      <div className="hero__inner">
        <div className="titleplate">
          <span className="type-metadata">A small, nimble, full-stack</span>
          <h1 className="type-title titleplate__title type-uppercase">
            <span>Web design</span>
            <br />
            <img
              src={withPrefix('images/ampersand.svg')}
              className="titleplate__ampersand"
              alt="&"
            />
            <span>Development</span>
          </h1>
          <span className="type-metadata">studio based in Cheltenham, UK</span>
          <Link
            to="/contact"
            className="btn-alpha titleplate__cta"
            title="Get in touch"
          >
            <svg viewBox="0 0 48 48" width="18" height="18">
              <use xlinkHref="#icon-chat" />
            </svg>
            <span>Get in touch</span>
          </Link>
        </div>
      </div>
    </header>

    <section id="projects" className="wrapper-chunky wrapper--top-only overflow--hidden">
      <header className="relative">
        <div class="type-backdrop">
          <HomepageSVG />
        </div>
        <div class="container-mini">
        <h3 className="type-intro">
          If it's something that belongs on the internet, in a browser, we can
          create it<span className="type-highlight">.</span>
        </h3>
        </div>
      </header>
      <div className="container-mini island-large relative">
      <p className="type-body">
          We are a digital development team based Cheltenham, England with a
          combined 20 years of experience implementing digital solutions for
          leading brands and marketing agencies.
        </p>
        <p className="type-body island">
          We specialise in creating bespoke, data-driven, forward-thinking web
          applications, built using React JS & Laravel PHP.
        </p>
        <Link
          to="/services"
          className="type-textlink view-all"
          title="View all services"
        >
          <span className="type-label">View all services</span>
          <svg
            viewBox="0 0 74 8"
            width="74"
            height="8"
          >
            <use xlinkHref="#icon-arrow-right" />
          </svg>
        </Link>
      </div>
      <div className="container-wide">
        <div className="projects">
          <TipCard />
          <PeelCard />
          <PergolasCard />
          <MedicalCard />
          <CirenHousingCard />
        </div>
      </div>

</section>

      <section className="wrapper-chunky flow">

        <header className="relative text-align--center">
          <div class="container-mini">
          <span className="type-metadata">Our projects are only half the tale. Here's</span>
          <h2 className="type-title type-uppercase">Who we've worked with</h2>
          </div>
        </header>

      <div className="container-mini">
        <p className="type-body">
          For the past five years we have worked with global brands, leading
          marketing agencies, established small businesses and startups to help
          deliver best in class digital solutions to improve productivity,
          increase sales, rebrand and launch new ventures.
        </p>
      </div>

      <div className="container-medium">
        <div class="clients">
          <a
            href="https://www.lynda.com/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Lynda.com"
            className="clients__logo"
          >
            <svg viewBox="0 0 120 29" width="120" height="29">
              <use xlinkHref="#icon-client-lynda" />
            </svg>
          </a>
          <a
            href="https://www.mitie.com/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Mitie"
            className="clients__logo"
          >
            <svg viewBox="0 0 80 29" width="80" height="29">
              <use xlinkHref="#icon-client-mitie" />
            </svg>
          </a>
          <a
            href="https://www.theindependentpharmacy.co.uk/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="The Independent Pharmacy"
            className="clients__logo"
          >
            <svg viewBox="0 0 100 31" >
              <use xlinkHref="#icon-client-tip" />
            </svg>
          </a>
          <a
            href="https://www.cirencesterhousing.org.uk/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Cirencester Housing"
            className="clients__logo"
          >
            <svg viewBox="0 0 505 148" >
              <use xlinkHref="#icon-client-ciren-housing" />
            </svg>
          </a>
          <a
            href="https://www.mccann.com/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="McCann"
            className="clients__logo"
          >
            <svg viewBox="0 0 110 20" width="110" height="20">
              <use xlinkHref="#icon-client-mccann" />
            </svg>
          </a>
          <a
            href="http://cleeve.co.uk"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Cleeve Residential"
            className="clients__logo"
          >
            <svg viewBox="0 0 52 52" width="56" height="56">
              <use xlinkHref="#icon-client-cleeve" />
            </svg>
          </a>
          <a
            href="https://www.perfectpergolas.co.uk/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Perfect Pergolas"
            className="clients__logo"
          >
            <svg viewBox="0 0 400 200" >
              <use xlinkHref="#icon-client-pergolas" />
            </svg>
          </a>
          <a
            href="https://www.thecornerlondon.com/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="The Corner"
            className="clients__logo"
          >
            <svg viewBox="0 0 172 40" width="172" height="40">
              <use xlinkHref="#icon-client-corner" />
            </svg>
          </a>
          <a
            href="https://www.simplicityinbusiness.co.uk/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Simplicity In Business"
            className="clients__logo"
          >
            <svg viewBox="0 0 120 33" width="120" height="33">
              <use xlinkHref="#icon-client-simplicity" />
            </svg>
          </a>
          <a
            href="https://www.pulseadvancedmedia.co.uk/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Pulse Advanced Media"
            className="clients__logo"
          >
            <svg viewBox="0 0 100 30" width="100" height="30">
              <use xlinkHref="#icon-client-pulse" />
            </svg>
          </a>
          <a
            href="https://love2buildltd.co.uk/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Love 2 Build"
            className="clients__logo"
          >
            <img src="/images/clients/love-to-build.svg" alt="Love 2 Build" />
          </a>
          <a
            href="https://zone.projectpeel.org/"
            rel="nofollow noopener noreferrer"
            target="_blank"
            title="Peel Zone"
            className="clients__logo"
          >
            <svg viewBox="0 0 212 69.24" width="80" height="40">
              <use xlinkHref="#icon-client-peel" />
            </svg>
          </a>
        </div>

      </div>
    </section>
    <AreYouAnAgency />
    <section className="wrapper-chunky flow overflow--hidden">

      <header className="relative text-align--center">
        <div class="type-backdrop">
          <CrewSVG />
        </div>
        <span className="type-metadata">Every ship needs</span>
        <h3 className="type-title type-uppercase">The right crew</h3>
      </header>

      <div class="container-medium flow">
      <p className="type-body max-width--30">
          All our work is completed in house by a dedicated team and we
          never outsource work to third parties for our{' '}
          <a
            href="/services"
            className="type-textlink"
            title="Our services"
          >
            core service offering
          </a>
          . You will always be able to talk to someone who is linked to your
          project and can provide clear answers to your enquiries.
        </p>
        <hr className="sep-short-red" />

        <div class="cluster cluster--loose">
      <ul class="team-list">
          <li className="cluster__item team-member flow--xsmall" >
            <div className="team-member__name">
              <h3 className="type-label text-color--ruby">Daryl Thornhill</h3>
              <small>Co-Founder &amp; Developer</small>
            </div>
              <Img
                fluid={props.data.daryl.childImageSharp.fluid}
                alt="Daryl Thornhill"
                className="team-member__headshot"
              />
          </li>
          <li class="cluster__item team-member flow--xsmall">
            <div className="team-member__name">
              <h3 className="type-label text-color--ruby">Liam Coates</h3>
              <small>Co-Founder &amp; Designer</small>
            </div>
            <Img
              fluid={props.data.liam.childImageSharp.fluid}
              alt="Liam Coates"
              className="team-member__headshot"
            />
          </li>
          <li class="cluster__item team-member flow--xsmall">
            <div className="team-member__name">
              <h3 className="type-label text-color--ruby">Phillip Davies</h3>
              <small>Co-Founder &amp; Developer</small>
            </div>
            <Img
              fluid={props.data.phillip.childImageSharp.fluid}
              alt="Phillip Davies"
              className="team-member__headshot"
            />
          </li>
          <li class="cluster__item team-member flow--xsmall">
            <div className="team-member__name">
                <h3 className="type-label text-color--ruby">Adam Mason</h3>
                <small>Developer</small>
              </div>
              <Img
                fluid={props.data.adam.childImageSharp.fluid}
                alt="Adam Mason"
                className="team-member__headshot"
              />
          </li>
          <li class="cluster__item team-member flow--xsmall team-member--hire">
            <div className="team-member__name flow--xsmall">
              <h3 className="type-label">We've hired a</h3>
                <small>Senior Backend Developer</small>
            </div>
            <Img
              fluid={props.data.dinosaur.childImageSharp.fluid}
              alt="A person disguised as a dinosaur"
              className="team-member__headshot team-member--hire__headshot"
            />
          </li>
        </ul>
        </div>

      </div>

    </section>
    <ReadyToWork />
  </Layout>
)

export const query = graphql`
  query {
    homeHero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1680) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    daryl: file(relativePath: { eq: "about/daryl.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    adam: file(relativePath: { eq: "about/adam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    liam: file(relativePath: { eq: "about/liam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phillip: file(relativePath: { eq: "about/phillip.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    dinosaur: file(relativePath: { eq: "about/dinosaur.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
